import axios from "axios";
//import https from "https";
import store from "./../store/index";

/*
diploma digital BRY
client_id    : 79a23c6d-519d-4a2d-a113-9a9430803ff1
client_secret: PQU+XaRQDo9drt5JK8RpzywNzsI854GXVZleEui8DP7ZVPn9QMgAUg==
Access token : eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJFTjI1UTRjTnZEYW1XSjFuUUVIdmhzN1k0VDZEZlhxQnNGZ0N0WVBHS2Y4In0.eyJleHAiOjE2NDc5NTg3NjcsImlhdCI6MTY0Nzk1Njk2NywianRpIjoiM2Q1YWE2MWYtMjRhYi00ZGE1LWEyNGEtYWZlNjRmOTQxOGRlIiwiaXNzIjoiaHR0cHM6Ly9jbG91ZC5icnkuY29tLmJyL2F1dGgvcmVhbG1zL2Nsb3VkIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6ImY6ZWExZDg2NGYtNzg3MS00M2Q2LWJjYmYtMTE4N2M3ZmI4MTg2OjMzNTU3NzEzNTA0IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoidGVyY2Vpcm9zIiwic2Vzc2lvbl9zdGF0ZSI6IjM4NjQ2ZmM0LTUxYzctNDdiNi1hYjUxLTMxYmQ0NzBlMjM1ZCIsImFjciI6IjEiLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJ1c3VhcmlvIiwidW1hX2F1dGhvcml6YXRpb24iLCJ1c2VyIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIiwiY29kZSI6IjMzNTU3NzEzNTA0IiwiY2xvdWRfYXBwX2tleSI6ImFwcC4zMzU1NzcxMzUwNC4xNjQ3OTU2ODI5NDU4IiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiT1NWQUxESU5PICBFVkFOR0VMSVNUQSBERSBNRUxPIiwiY2xvdWRfdG9rZW4iOiJkaFVvYmM2WkdxendtdWQyTkJNbWs3SWJmQ1RwVDVWMWxJVjR1TWFKM2UyZzE4UDdOY2MyeHJUT2lRaWJhdk9zIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiMzM1NTc3MTM1MDQiLCJnaXZlbl9uYW1lIjoiT1NWQUxESU5PIiwiZmFtaWx5X25hbWUiOiIgRVZBTkdFTElTVEEgREUgTUVMTyJ9.AyN3wLX-pxrSE4oDxu9aeN4rWrJW593N438pPJB_twPJajIfHlktgjde82t3csj5xlnqPMTVh2SjIphlzd7EVzrU7PpVDnf-1ZX3TKIFoiED7uF5P9PF7U75aKq6yWrCzzz5SVmYYMymA4qXs8rDvYduAn66b1JTfH7vvqCqoO1fpF5idkR0NXXDNDuKVXNROfj2FIXWzbF3Q5IWNE-OHNKqjgLYw96R9VDco_w9y9hwj6W0O5REoaLk2a8FRs1mrueU45vJbVC6ZzBlJm4TEKOX_pP5GyV1s53HxZolan-oTb7qOVyFcg_F9D2bNINW_4EBSeyh1oMgMm8ROisH7A
Refresh token: eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI5NzQ5ZTgyYi1jNGVlLTQ3OGYtODUyMS1mM2RjMDg2NjNiYmIifQ.eyJleHAiOjE2NDc5NTg3NjcsImlhdCI6MTY0Nzk1Njk2NywianRpIjoiNTBlMTJjNGItYzY2Zi00NzdjLTlkN2QtNjdmY2UzOWRiMjYxIiwiaXNzIjoiaHR0cHM6Ly9jbG91ZC5icnkuY29tLmJyL2F1dGgvcmVhbG1zL2Nsb3VkIiwiYXVkIjoiaHR0cHM6Ly9jbG91ZC5icnkuY29tLmJyL2F1dGgvcmVhbG1zL2Nsb3VkIiwic3ViIjoiZjplYTFkODY0Zi03ODcxLTQzZDYtYmNiZi0xMTg3YzdmYjgxODY6MzM1NTc3MTM1MDQiLCJ0eXAiOiJSZWZyZXNoIiwiYXpwIjoidGVyY2Vpcm9zIiwic2Vzc2lvbl9zdGF0ZSI6IjM4NjQ2ZmM0LTUxYzctNDdiNi1hYjUxLTMxYmQ0NzBlMjM1ZCIsInNjb3BlIjoicHJvZmlsZSBlbWFpbCJ9.nhUK8B7CIXkTozyGguO91f4tHWE-L_c0HRwdp-PUk6U
*/

 const resposta = await fetch("params.json");
 const json = await resposta.json();
 
 if (json.EMPRESA === 'DOMPEDRO') {
     if (document.URL.includes('10.1.1.104') || document.URL.includes('localhost')) {
         json.EMPRESA = "DOMPEDRO104"
     }   
}
 
    console.log('window.location.host.split("."): ', window.location.host.split("."));

var h = window.location.host;

    if (h.includes("localhost") || h.includes("192.168.5.207")) {
      h = "sistemajaguar.com.br"
};

    var host = h.split(".");  //window.location.host.split(".");  //"academico.faculaddeolgamettig.com.br".split("."); // window.location.host.split(".");

    var hostname = "";
    var ii = 0;

    for (var i = host.length - 1; i >= 0; i--) {
      if (host[i] !== undefined) {
        if (ii > 0 || host.length === 3) {
          hostname = hostname + "." + host[ii];
        }
      }
      ii++;
    }

  store.commit("setHostName", hostname.substring(1, hostname.length));
json.EMPRESA = hostname.substring(1, hostname.length);  
console.log('json', json);

 store.state.params.baseApiUrl = json[json.EMPRESA].baseApiUrl.split('sistemajaguar.com.br').join(store.state.params.hostname);
 store.state.params.relatorio = json[json.EMPRESA].baseApiRel.split('sistemajaguar.com.br').join(store.state.params.hostname);
 store.state.params.relatoriopdf = json[json.EMPRESA].baseApiRelPDF.split('sistemajaguar.com.br').join(store.state.params.hostname);
 store.state.params.relatorioxls = json[json.EMPRESA].baseApiRelXLS.split('sistemajaguar.com.br').join(store.state.params.hostname);
 store.state.params.uploadlarge = json[json.EMPRESA].baseApiUrlUploadlarge.split('sistemajaguar.com.br').join(store.state.params.hostname); // = "http://jaguar.solutio.net.br:3000/upload"
 store.state.params.upload3000 = json[json.EMPRESA].baseApiUrlUpload3000.split('sistemajaguar.com.br').join(store.state.params.hostname); // = "http://jaguar.solutio.net.br:3000"
 store.state.params.upload = json[json.EMPRESA].baseApiUrlUpload.split('sistemajaguar.com.br').join(store.state.params.hostname); // = "http://jaguar.solutio.net.br:3000"
 store.state.params.viewdocs = json[json.EMPRESA].baseApiUrlViewDocs.split('sistemajaguar.com.br').join(store.state.params.hostname);
 store.state.params.socket = json[json.EMPRESA].baseapisocket.split('sistemajaguar.com.br').join(store.state.params.hostname);
 store.state.params.docs = json[json.EMPRESA].baseApiDocs.split('sistemajaguar.com.br').join(store.state.params.hostname);


 //const baseApiUrl = "http://10.1.1.104:9001";  //Dom Pedro
// const baseApiUrl = "http://192.168.5.171:9002";  //Fatec
  const baseApiUrl =  store.state.params.baseApiUrl  //"http://jaguar.solutio.net.br:9002";  //Fatec

//console.log('baseapiurl', baseApiUrl);

//  const baseApiUrl = "http://192.168.3.2:9002";  //Fazag
//  const baseApiUrl = "http://academico.fan-edu.com.br:9002";  //FAN
//const baseApiUrl = "http://192.168.5.171:9002";  //Local

//   const baseApiUrl = "https://192.168.5.171:9002";  //Fatec /  Java


//Dom pedro II

/*

var baseapi = ''

if (document.URL.includes('10.1.1.104') || document.URL.includes('localhost')) {
 baseapi= '10.1.1.104'
} else {
 baseapi = '200.128.108.5'//'191.251.193.210' 
}
const baseApiUrl = `http://${baseapi}:9002`;  //Dom Pedro
*/
// fim dompedro


export default {
  /*async send(destino, params, method = 'get' ) {
    const http = axios.create({
      baseURL: `${baseApiUrl}/fxjaguar`,
    });
*/

  async send(destino, params, method = 'get', servidor = 'java') {

    var porta = destino === "email" ? "9001" : "9002";

    if (servidor === 'java') {
      const httpJava = axios.create();
    }

    function Right(str, n) {
      if (n <= 0)
        return "";
      else if (n > String(str).length)
        return str;
      else {
        var iLen = String(str).length;
        return String(str).substring(iLen, iLen - n);
      }
    }

    /*
    var bco   = false;
    var linha = "";
    var linha_java = params.proc;
    var args_java = {};
    var d     = "";
    */

    var dados : any = []
    var erro = false;

    if (!params.banco || params.banco === undefined) {
      try {
        params.banco = (store.state.contexto as any).BD === null
          ? "manager_jaguar"
          : (store.state.contexto as any).BD[0].bco_nome;
      } catch (e) {
        params.banco = params.banco
      }
    }

    var idx = 0;
    var params_novo = {};

    if (destino === "") {
      if (params !== undefined) {
        for (var prop in params) {
          if (prop !== "proc" && prop !== "banco") {
            params_novo[Right('000' + idx, 3)] = params[prop];
            idx++;
          } else {
            params_novo[prop] = params[prop];
          }

        }
      } else {
        params_novo = params
      }

    } else {
      params_novo = params

    }
    if (process.env.NODE_ENV === "development") {
    console.log('process.env', process.env)    
    console.log(params_novo);      
    }

//    console.log('token ', store.state.token);

    if (destino === "") {
      destino = "jaguar"
    }

     //const httpsAgent = new https.Agent({ rejectUnauthorized: false });
    
  console.log(
      "${baseApiUrl}/${destino}/",
      `${baseApiUrl}/${destino}/`
    );
    
    console.log('store.state.token', store.state.token)
    
    await axios({
      method: 'post', //you can set what request you want to be
      url: `${baseApiUrl}/${destino}/`, //'http://localhost:8001/jaguar/',
      data: JSON.stringify(params_novo).toString(),
      
      
//      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': store.state.token
      },
      //,

     // httpsAgent: new https.Agent({
      //  rejectUnauthorized: true
     // })

    }).catch((e)=>{ console.log('Erro: ' + e.response.data.message)})
      
      .then((response) => {
      dados = response;
    }, (error) => {
      dados.push(error);
    });

    var retorno = { dados: dados, erro: erro }

    return retorno;

  },
};

